import { Routes, Route } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import "./App.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GrigoraVersioning } from "./components/versioning/context";
import React, { Suspense, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import LoginRequired from "./components/account/loginRequired";
import { Account } from "./components/account/account";
import AuthSkeleton from "./components/skeletons/auth-skeleton";
import NotFoundSkeleton from "./components/skeletons/not-found-skeleton";
import ProjectsSkeleton from "./components/skeletons/projects-skeleton";
import NewProjectSkeleton from "./components/skeletons/new-project-skeleton";
import StartNewProjectSkeleton from "./components/skeletons/start-new-project-skeleton";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { APIProvider } from "./components/api";
import MainSkeleton from "./components/skeletons/main-skeleton";
import ReactGA from "react-ga4";
import StartNewLoadable from "./pages/loadables/start-new";
import AnalyticsLoadable from "./pages/loadables/analytics";
import LeadsLoadable from "./pages/loadables/leads";
import TeamsLoadable from "./pages/loadables/teams";
import SettingsLoadable from "./pages/loadables/settings";
import WebsitesLoadable from "./pages/loadables/website";
import Code from "./components/code";
import * as pixel from "./components/fpixel";
import LinkedInTracker from "./components/linkedin-tracking";

const ShopifyConnect = React.lazy(() =>
  import(
    /* webpackChunkName: "page-shopify-connect" */
    /* webpackMode: "lazy" */
    "./pages/shopify"
  ),
);

const ShopifyVerify = React.lazy(() =>
  import(
    /* webpackChunkName: "page-shopify-verify" */
    /* webpackMode: "lazy" */
    "./pages/shopify/verify"
  ),
);

const AcceptInvitation = React.lazy(() =>
  import(
    /* webpackChunkName: "page-accept-invitation" */
    /* webpackMode: "lazy" */
    "./pages/accept-invitation"
  ),
);

const AppsumoConnect = React.lazy(() =>
  import(
    /* webpackChunkName: "page-appsumo-connect" */
    /* webpackMode: "lazy" */
    "./pages/appsumo"
  ),
);

const NavigationLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "page-navigation-layout" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    "./components/nav-layout"
  ),
);

// Convert all the above pages into lazy loaded components
const Login = React.lazy(() =>
  import(
    /* webpackChunkName: "page-login" */
    /* webpackMode: "lazy" */
    "./pages/login/login"
  ),
);
const Logout = React.lazy(() =>
  import(
    /* webpackChunkName: "page-logout" */
    /* webpackMode: "lazy" */
    "./pages/logout"
  ),
);
const NotFound404 = React.lazy(() =>
  import(
    /* webpackChunkName: "page-404" */
    /* webpackMode: "lazy" */
    "./pages/404/404"
  ),
);
const Register = React.lazy(() =>
  import(
    /* webpackChunkName: "page-register" */
    /* webpackMode: "lazy" */
    "./pages/register/register"
  ),
);

const Projects = React.lazy(() =>
  import(
    /* webpackChunkName: "page-projects" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    "./pages/projects/projects"
  ),
);
const Oauth = React.lazy(() =>
  import(
    /* webpackChunkName: "page-oauth" */
    /* webpackMode: "lazy" */
    "./pages/oauth/oauth"
  ),
);

const ConstantContactOauth = React.lazy(() =>
  import(
    /* webpackChunkName: "page-constant-contact-oauth" */
    /* webpackMode: "lazy" */
    "./pages/constant-contact/oauth"
  ),
);

const GoogleSheetsOauth = React.lazy(() =>
  import(
    /* webpackChunkName: "page-google-sheets-oauth" */
    /* webpackMode: "lazy" */
    "./pages/google-sheets/oauth"
  ),
);

const SlackOauth = React.lazy(() =>
  import(
    /* webpackChunkName: "page-slack-oauth" */
    /* webpackMode: "lazy" */
    "./pages/slack/oauth"
  ),
);

const HubspotOauth = React.lazy(() =>
  import(
    /* webpackChunkName: "page-hubspot-oauth" */
    /* webpackMode: "lazy" */
    "./pages/hubspot/oauth"
  ),
);

const BirdsendOauth = React.lazy(() =>
  import(
    /* webpackChunkName: "page-birdsend-oauth" */
    /* webpackMode: "lazy" */
    "./pages/birdsend/oauth"
  ),
);

const AweberOauth = React.lazy(() =>
  import(
    /* webpackChunkName: "page-aweber-oauth" */
    /* webpackMode: "lazy" */
    "./pages/aweber/oauth"
  ),
);

const ZohoOauth = React.lazy(() =>
  import(
    /* webpackChunkName: "page-zoho-oauth" */
    /* webpackMode: "lazy" */
    "./pages/zoho/oauth"
  ),
);

// Lazy load forgot password
const ForgotPassword = React.lazy(() =>
  import(
    /* webpackChunkName: "page-forgotpassword" */
    /* webpackMode: "lazy" */
    "./pages/login/forgotPassword"
  ),
);

const AdminNavigationLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-navigation-layout" */
    /* webpackMode: "lazy" */
    "./components/admin-nav-layout"
  ),
);

const AdminDashboard = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-dashboard" */
    /* webpackMode: "lazy" */
    "./components/admin/dashboard"
  ),
);

const AdminCustomers = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-customers" */
    /* webpackMode: "lazy" */
    "./components/admin/customers"
  ),
);

const AdminEditCustomer = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-edit-customers" */
    /* webpackMode: "lazy" */
    "./components/admin/edit-customer"
  ),
);

const AdminSearchLogs = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-search-logs" */
    /* webpackMode: "lazy" */
    "./components/admin/search_logs"
  ),
);

const AdminTestimonials = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-testimonials" */
    /* webpackMode: "lazy" */
    "./components/admin/testimonials"
  ),
);

const AdminEditWebsite = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-edit-website" */
    /* webpackMode: "lazy" */
    "./components/admin/edit-website"
  ),
);

const AdminEditPopup = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-edit-popup" */
    /* webpackMode: "lazy" */
    "./components/admin/edit-popup"
  ),
);

const AdminWebsites = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-websites" */
    /* webpackMode: "lazy" */
    "./components/admin/websites"
  ),
);

const AdminTemplates = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-templates" */
    /* webpackMode: "lazy" */
    "./components/admin/templates"
  ),
);

const AdminEditTemplate = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-edit-template" */
    /* webpackMode: "lazy" */
    "./components/admin/templates/edit"
  ),
);

const AdminCategoryTemplates = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-templates" */
    /* webpackMode: "lazy" */
    "./components/admin/templates/category/"
  ),
);

const AdminEditCategoryTemplates = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-templates" */
    /* webpackMode: "lazy" */
    "./components/admin/templates/category/edit"
  ),
);

const AdminIntegrations = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-templates" */
    /* webpackMode: "lazy" */
    "./components/admin/integrations"
  ),
);

const AdminEditIntegrations = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-templates" */
    /* webpackMode: "lazy" */
    "./components/admin/integrations/edit"
  ),
);

const AdminCategoryIntegrations = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-templates" */
    /* webpackMode: "lazy" */
    "./components/admin/integrations/category/"
  ),
);

const AdminEditCategoryIntegrations = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-templates" */
    /* webpackMode: "lazy" */
    "./components/admin/integrations/category/edit"
  ),
);

const AdminUsecases = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-templates" */
    /* webpackMode: "lazy" */
    "./components/admin/usecases"
  ),
);

const AdminEditUsecases = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-templates" */
    /* webpackMode: "lazy" */
    "./components/admin/usecases/edit"
  ),
);

const AdminPopups = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-popups" */
    /* webpackMode: "lazy" */
    "./components/admin/popups"
  ),
);

const AdminSettings = React.lazy(() =>
  import(
    /* webpackChunkName: "page-admin-settings" */
    /* webpackMode: "lazy" */
    "./components/admin/settings"
  ),
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<MainSkeleton />}>
              <NavigationLayout />
            </Suspense>
          </LoginRequired>
        }
      >
        <Route
          path="/settings/:settingType"
          element={<SettingsLoadable />}
        ></Route>
        <Route
          path="/"
          element={
            <Suspense fallback={<ProjectsSkeleton />}>
              <Projects />
            </Suspense>
          }
        />
        <Route path="/analytics" element={<AnalyticsLoadable />} />
        <Route path="/code" element={<Code />} />
        <Route path="/leads" element={<LeadsLoadable />} />
        <Route path="/team" element={<TeamsLoadable />} />
        <Route path="/websites" element={<WebsitesLoadable />} />
        <Route path="/popup/:popupID" element={<StartNewLoadable />} />
        <Route path="/start" element={<StartNewLoadable newPopup={true} />} />
      </Route>
      <Route
        path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}`}
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<NewProjectSkeleton />}>
              <AdminNavigationLayout />
            </Suspense>
          </LoginRequired>
        }
      >
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminDashboard />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/customers`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminCustomers />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/search_logs`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminSearchLogs />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/testimonials`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminTestimonials />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/websites`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminWebsites />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/templates`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminTemplates />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/templates/new`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminEditTemplate isNew={true} />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/templates/edit/:id`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminEditTemplate />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/templates/category`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminCategoryTemplates />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/templates/category/new`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminEditCategoryTemplates isNew={true} />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/templates/category/edit/:id`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminEditCategoryTemplates />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/integrations`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminIntegrations />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/integrations/new`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminEditIntegrations isNew={true} />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/integrations/edit/:id`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminEditIntegrations />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/integrations/category`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminCategoryIntegrations />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/integrations/category/new`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminEditCategoryIntegrations isNew={true} />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/integrations/category/edit/:id`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminEditCategoryIntegrations />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/usecases`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminUsecases />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/usecases/new`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminEditUsecases isNew={true} />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/usecases/edit/:id`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminEditUsecases />
            </Suspense>
          }
        />

        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/popups`}
          element={
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AdminPopups />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/customer/:customerEmail`}
          element={
            <Suspense fallback={<NewProjectSkeleton />}>
              <AdminEditCustomer />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/customer/:customerEmail/website/:website`}
          element={
            <Suspense fallback={<NewProjectSkeleton />}>
              <AdminEditWebsite />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/customer/:customerEmail/popup/:popupID`}
          element={
            <Suspense fallback={<NewProjectSkeleton />}>
              <AdminEditPopup />
            </Suspense>
          }
        />
        <Route
          path={`/${process.env.REACT_APP_POPER_ADMIN_ROUTE}/settings`}
          element={
            <Suspense fallback={<NewProjectSkeleton />}>
              <AdminSettings />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/appsumo/connect"
        element={
          <APIProvider>
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AppsumoConnect />
            </Suspense>
          </APIProvider>
        }
      />
      <Route
        path="/shopify/connect"
        element={
          <APIProvider>
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <ShopifyConnect />
            </Suspense>
          </APIProvider>
        }
      />
      <Route
        path="/shopify/verify"
        element={
          <APIProvider>
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <ShopifyVerify />
            </Suspense>
          </APIProvider>
        }
      />
      <Route
        path="login"
        element={
          <LoginRequired not redirect={"/"}>
            <Suspense fallback={<AuthSkeleton />}>
              <Login />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="/"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<></>}>
              <Projects />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="/accept-invitation"
        element={
          <APIProvider>
            <Suspense fallback={<StartNewProjectSkeleton />}>
              <AcceptInvitation />
            </Suspense>
          </APIProvider>
        }
      />
      <Route
        path="login"
        element={
          <LoginRequired not redirect={"/"}>
            <Suspense fallback={<></>}>
              <Login />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="forgot"
        element={
          <LoginRequired not redirect={"/"}>
            <Suspense fallback={<AuthSkeleton screen="forgot" />}>
              <ForgotPassword />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="oauth"
        element={
          <LoginRequired bypass redirect={"/"}>
            <Suspense fallback={<></>}>
              <Oauth />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="constant-contact-oauth"
        element={
          <LoginRequired bypass redirect={"/"}>
            <Suspense fallback={<></>}>
              <ConstantContactOauth />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="google-sheets-oauth"
        element={
          <LoginRequired bypass redirect={"/"}>
            <Suspense fallback={<></>}>
              <GoogleSheetsOauth />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="slack-oauth"
        element={
          <LoginRequired bypass redirect={"/"}>
            <Suspense fallback={<></>}>
              <SlackOauth />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="hubspot-oauth"
        element={
          <LoginRequired bypass redirect={"/"}>
            <Suspense fallback={<></>}>
              <HubspotOauth />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="birdsend-oauth"
        element={
          <LoginRequired bypass redirect={"/"}>
            <Suspense fallback={<></>}>
              <BirdsendOauth />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="aweber-oauth"
        element={
          <LoginRequired bypass redirect={"/"}>
            <Suspense fallback={<></>}>
              <AweberOauth />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="zoho-oauth"
        element={
          <LoginRequired bypass redirect={"/"}>
            <Suspense fallback={<></>}>
              <ZohoOauth />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="sign-up"
        element={
          <LoginRequired not redirect={"/"}>
            <Suspense fallback={<AuthSkeleton />}>
              <Register />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="logout"
        element={
          <LoginRequired redirect={"/login"}>
            <Suspense fallback={<></>}>
              <Logout />
            </Suspense>
          </LoginRequired>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<NotFoundSkeleton />}>
            <NotFound404 />
          </Suspense>
        }
      />
    </>,
  ),
);

//Zoho Sales Iq Script:
const UseScript = () => {
  useEffect(() => {
    if (
      !process.env.REACT_APP_NODE_ENV ||
      process.env.REACT_APP_NODE_ENV === "development"
    ) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siqa17723e4426dc119138cfd31ea6036d8c725d09a956073079786023650418c81", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.in/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
    $zoho.salesiq.ready=function(embedinfo){
      $zoho.salesiq.tracking.domain("poper.ai");
 }
    `;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    return () => {
      if (
        !process.env.REACT_APP_NODE_ENV ||
        process.env.REACT_APP_NODE_ENV === "development"
      ) {
        return;
      }

      document.body.removeChild(script);
    };
  }, []);
};

const FacebookPixel = () => {
  useEffect(() => {
    // if (
    //   !process.env.REACT_APP_NODE_ENV ||
    //   process.env.REACT_APP_NODE_ENV === "development"
    // ) {
    //   return;
    // }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    script.setAttribute("id", "fb-pixel");
    script.setAttribute("src", "/scripts/pixel.js");
    script.setAttribute("defer", true);
    script.setAttribute("data-pixel-id", pixel.FB_PIXEL_ID);

    document.body.appendChild(script);

    return () => {
      // if (
      //   !process.env.REACT_APP_NODE_ENV ||
      //   process.env.REACT_APP_NODE_ENV === "development"
      // ) {
      //   return;
      // }

      document.body.removeChild(script);
    };
  }, [pixel.FB_PIXEL_ID]);

  return;
};

const PoperTracking = () => {
  useEffect(() => {
    // if (
    //   !process.env.REACT_APP_NODE_ENV ||
    //   process.env.REACT_APP_NODE_ENV === "development"
    // ) {
    //   return;
    // }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    script.setAttribute("id", "poper-tracking");
    script.setAttribute("src", "/tracking/poper.js");
    script.setAttribute("defer", true);

    document.body.appendChild(script);

    return () => {
      // if (
      //   !process.env.REACT_APP_NODE_ENV ||
      //   process.env.REACT_APP_NODE_ENV === "development"
      // ) {
      //   return;
      // }

      document.body.removeChild(script);
    };
  }, []);

  return;
};

function App() {
  const FadeUp = cssTransition({
    enter: "toast-animated toast-fadeInLeft",
    exit: "toast-animated toast-fadeOutLeft",
    collapse: false,
    duration: 300,
  });

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID);
  }, []);

  return (
    // <BrowserRouter>
    <HelmetProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#f14a16",
            fontFamily: "Poppins, sans-serif",
          },
          components: {
            Select: {
              zIndex: 1000000,
            },
          },
        }}
      >
        <FacebookPixel />
        <LinkedInTracker />
        <PoperTracking />
        <GrigoraVersioning>
          <Account>
            <ToastContainer
              limit={3}
              transition={FadeUp}
              position="bottom-left"
              autoClose={2000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={{ zIndex: 99999999 }}
              theme="light"
              toastClassName={"g-toastify"}
            />
            <AnimatePresence>
              <RouterProvider router={router} />
            </AnimatePresence>
          </Account>
        </GrigoraVersioning>
      </ConfigProvider>
      <UseScript />
    </HelmetProvider>
    // </BrowserRouter>
  );
}

export default App;
<script type="text/javascript" id="zsiqchat"></script>;
