import GRIGORA_APP from "../../../package.json";
import React, { createContext } from "react";

const GrigoraVersioningContext = createContext();

const GrigoraVersioning = (props) => {
  return (
    <GrigoraVersioningContext.Provider
      value={{
        version: GRIGORA_APP.version,
        versionName: GRIGORA_APP.versionName,
      }}
    >
      {props.children}
    </GrigoraVersioningContext.Provider>
  );
};
export { GrigoraVersioning, GrigoraVersioningContext };
