export const PLANS = [
  {
    label: "Free",
    id: "free",
    price: 0,
    list: [
      "10k Pageviews/Month",
      "50 AI Credits",
      "1 website",
      "Unlimited Popups",
      "Create using AI",
      "Branded",
    ],
    limits: {
      website: 1,
      ai: 50,
      pageviews: 10000,
    },
  },
  {
    label: "Demo",
    id: "demo",
    price: 0,
    list: [
      "20k Pageviews/Month",
      "500 AI Credits",
      "5 website",
      "Unlimited Popups",
      "Create using AI",
      "Branded",
    ],
    limits: {
      website: 5,
      ai: 500,
      pageviews: 20000,
    },
  },
  {
    label: "Basic",
    id: "basic",
    price: 9,
    canBuy: true,
    list: [
      "50k Pageviews/Month",
      "500 AI Credits",
      "5 websites",
      "Unlimited Popups",
      "Create using AI",
      "Unbranded",
    ],
    limits: {
      website: 5,
      ai: 500,
      pageviews: 50000,
    },
  },
  {
    label: "Growth",
    id: "growth",
    price: 25,
    canBuy: true,
    list: [
      "100k Pageviews/Month",
      "1500 AI Credits",
      "15 websites",
      "Unlimited Popups",
      "Create using AI",
      "Unbranded",
    ],
    limits: {
      website: 15,
      ai: 1500,
      pageviews: 100000,
    },
  },
  {
    label: "Scale",
    id: "scale",
    price: 49,
    canBuy: true,
    list: [
      "500k Pageviews/Month",
      "4000 AI Credits",
      "Unlimited websites",
      "Unlimited Popups",
      "Create using AI",
      "Unbranded",
    ],
    limits: {
      website: "Unlimited",
      ai: 4000,
      pageviews: 500000,
    },
  },
  {
    label: "AppSumo - Tier 1",
    id: "appsumo-1",
    price: 49,
    lifetime: true,
    list: [
      "75k Pageviews/Month",
      "1000 AI Credits",
      "10 websites",
      "Unlimited Popups",
      "Create using AI",
      "Unbranded",
    ],
    limits: {
      website: 10,
      ai: 1000,
      pageviews: 75000,
    },
  },
  {
    label: "AppSumo - Tier 2",
    id: "appsumo-2",
    price: 149,
    lifetime: true,
    list: [
      "300k Pageviews/Month",
      "4000 AI Credits",
      "40 websites",
      "Unlimited Popups",
      "Create using AI",
      "Unbranded",
    ],
    limits: {
      website: 40,
      ai: 4000,
      pageviews: 300000,
    },
  },
  {
    label: "AppSumo - Tier 3",
    id: "appsumo-3",
    price: 299,
    lifetime: true,
    list: [
      "1M Pageviews/Month",
      "10,000 AI Credits",
      "Unlimited websites",
      "Unlimited Popups",
      "Create using AI",
      "Unbranded",
    ],
    limits: {
      website: "Unlimited",
      ai: 10000,
      pageviews: 1000000,
    },
  },
  {
    label: "AppSumo - Tier 4",
    id: "appsumo-4",
    price: 499,
    lifetime: true,
    list: [
      "2M Pageviews/Month",
      "25000 AI Credits",
      "Unlimited websites",
      "Unlimited Popups",
      "Create using AI",
      "Unbranded",
    ],
    limits: {
      website: "Unlimited",
      ai: 25000,
      pageviews: 2000000,
    },
  },
];
