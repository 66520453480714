import React, { createContext, useContext, useState } from "react";
import { AccountContext } from "../account/account";
import qs from "qs";
import axiosRetry from "axios-retry";
import axios from "axios";

axiosRetry(axios, {
  retryDelay: (retryCount) => {
    return retryCount * 1000; // retry after 1, 2, 3, ... seconds
  },
});

const APIProviderContext = createContext();

const APIProvider = (props) => {
  const BASE_API_URL = process.env.REACT_APP_POPER_API_URL + "/";
  // const BASE_API_URL = "http://localhost:3607/";
  // const BASE_API_URL = "https://api.poper.ai/";
  // const BASE_API_URL = "https://poper-api-cors.poperhq.workers.dev/";

  const { userAttributes, currentUser, idToken, accessToken } =
    useContext(AccountContext) ?? {};

  const [alias, setAlias] = useState(null);

  const makeRequest = async (method, url, data, params, headers) => {
    const request = {
      method,
      url,
      data,
      params,
      headers: {
        ...headers,
        Authorization: idToken,
        aliasemail: alias?.owner,
      },
    };
    return axios(request);
  };

  const getUserDomains = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "GET",
          `${BASE_API_URL}user/domains`,
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getUserPopups = async (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "GET",
          `${BASE_API_URL}popup/list/v2`,
          null,
          params,
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getPopup = async (popup_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "GET",
          `${BASE_API_URL}popup/single`,
          null,
          {
            popup_id,
          },
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getPopupResponses = async (popup_id, per_page, page) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "GET",
          `${BASE_API_URL}popup/responses`,
          null,
          {
            popup_id,
            per_page,
            page,
          },
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const downloadPopupResponses = async (popup_id, page) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "GET",
          `${BASE_API_URL}popup/responses/download`,
          null,
          {
            popup_id,
          },
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const deletePopupResponses = async (ids) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}popup/responses/delete`,
          qs.stringify({
            ids,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const createPopup = async (
    popup_name,
    template_id,
    template_meta,
    template_conditions,
    publicFlag,
    domain,
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}popup/create`,
          qs.stringify({
            popup_name,
            template_id,
            template_meta,
            template_conditions,
            public: publicFlag,
            domain,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const updatePopup = async (
    popup_id,
    popup_name,
    template_id,
    template_meta,
    template_conditions,
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}popup/update`,
          qs.stringify({
            popup_id,
            popup_name,
            template_id,
            template_meta,
            template_conditions,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const createPopupPreview = async (template_meta, template_conditions) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}popup/save_preview`,
          qs.stringify({
            template_meta,
            template_conditions,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const makePopupPublic = async (popup_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "GET",
          `${BASE_API_URL}popup/make/public`,
          null,
          {
            popup_id,
          },
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const makePopupPrivate = async (popup_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "GET",
          `${BASE_API_URL}popup/make/private`,
          null,
          {
            popup_id,
          },
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const deletePopup = async (popup_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}popup/delete`,
          qs.stringify({
            popup_id,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const duplicatePopup = async (popup_id, domain_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}popup/duplicate`,
          qs.stringify({
            popup_id,
            domain_id,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const integrationAPI = async (action, params = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}integration/${action}`,
          qs.stringify({
            ...params,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const integrationAction = async (type, action, params = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}integration/${type}/${action}`,
          qs.stringify({
            type,
            ...params,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const uploadFile = async (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}user/upload`,
          formData,
          {},
          {
            "Content-Type": "multipart/form-data",
          },
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const userAccount = async (action, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}user/account/${action}`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const sendHeartbeat = async (action, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await makeRequest(
          "POST",
          `${BASE_API_URL}user/heartbeat`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  async function createStripePaymentLink(
    plan,
    promo,
    redirectTo,
    tolt_referral,
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}stripe/create_checkout_link`,
          qs.stringify({
            plan: plan,
            redirectTo: redirectTo,
            promo: promo,
            env: process.env.REACT_APP_NODE_ENV,
            tolt_referral: tolt_referral,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function getStripeCustomerPortal(redirectTo) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}stripe/customer_portal`,
          qs.stringify({
            redirectTo: redirectTo,
            env: process.env.REACT_APP_NODE_ENV,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function createLemonsqueezyPaymentLink(plan, promo, redirectTo) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}lemonsqueezy/create_checkout_link`,
          qs.stringify({
            plan: plan,
            redirectTo: redirectTo,
            promo: promo,
            env: process.env.REACT_APP_NODE_ENV,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function createShopifyPaymentLink(plan, promo, redirectTo, shop) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}shopify/create_checkout_link`,
          qs.stringify({
            plan: plan,
            redirectTo: redirectTo,
            promo: promo,
            env: process.env.REACT_APP_NODE_ENV,
            shop: shop,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function cancelShopifySubscription() {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}shopify/cancel_subscription`,
          qs.stringify({}),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function getLemonsqueezyCustomerPortal(redirectTo) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}lemonsqueezy/customer_portal`,
          qs.stringify({
            redirectTo: redirectTo,
            env: process.env.REACT_APP_NODE_ENV,
          }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function getUserData(action, params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}user/data`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function updateUserNames(first_name, last_name) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}user/update_name`,
          qs.stringify({ first_name, last_name }),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function apiActions(action, params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}user/api/${action}`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function searchActions(action, params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}search/${action}`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function customFontActions(action, params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}user/fonts/${action}`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function adminActions(action, params, type) {
    return new Promise(async (resolve, reject) => {
      try {
        if (type === "formdata") {
          var form = new FormData();
          for (var key in params) {
            if (params[key]) {
              form.append(key, params[key]);
            }
          }

          var response = await makeRequest(
            "POST",
            `${BASE_API_URL}admin/${action}`,
            form,
            {},
            {
              "Content-Type": "multipart/form-data",
            },
          );

          resolve(response.data);
        } else {
          var response = await makeRequest(
            "POST",
            `${BASE_API_URL}admin/${action}`,
            qs.stringify(params),
          );
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async function assetActions(action, params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}assets/${action}`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function domainActions(action, params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}domain/${action}`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function acquisitionActions(action, params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}user/acquisition/${action}`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function isValidInvitation(params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}general/user/invitation`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function integrationApiActions(action, params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}integration/api_keys/${action}`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function analyticsAction(action, params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}analytics/${action}`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function popupAIAction(action, params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}popup/ai/${action}`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function verifyShopifyHmac(params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}general/user/verify_shopify_hmac`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function verifyAppsumoCode(params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}general/user/verify_appsumo_code`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function connectShopifyCode(params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}user/connect_shopify_code`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function connectAppsumoLicense(params) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await makeRequest(
          "POST",
          `${BASE_API_URL}user/connect_appsumo_code`,
          qs.stringify(params),
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  return (
    <APIProviderContext.Provider
      value={{
        BASE_API_URL,
        alias,
        setAlias,
        getUserDomains,
        createPopup,
        getPopup,
        getUserPopups,
        deletePopup,
        duplicatePopup,
        updatePopup,
        getPopupResponses,
        downloadPopupResponses,
        deletePopupResponses,
        uploadFile,
        makePopupPublic,
        makePopupPrivate,
        integrationAction,
        integrationAPI,
        createStripePaymentLink,
        getStripeCustomerPortal,
        apiActions,
        userAccount,
        integrationApiActions,
        analyticsAction,
        popupAIAction,
        domainActions,
        isValidInvitation,
        verifyShopifyHmac,
        verifyAppsumoCode,
        connectAppsumoLicense,
        connectShopifyCode,
        createLemonsqueezyPaymentLink,
        createShopifyPaymentLink,
        cancelShopifySubscription,
        getLemonsqueezyCustomerPortal,
        getUserData,
        updateUserNames,
        adminActions,
        assetActions,
        acquisitionActions,
        customFontActions,
        searchActions,
        createPopupPreview,
        sendHeartbeat,
      }}
    >
      {props.children}
    </APIProviderContext.Provider>
  );
};
export { APIProvider, APIProviderContext };
